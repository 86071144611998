<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page=itemsPerPage
            :loading=loadingTable
            class="elevation-1"
            item-key="product_id"
            :key="`list-products-${tenantId}`"
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :options.sync="options"
            hide-default-footer
            disable-pagination
            dense
        >
            <template v-slot:top>
                
                 <ExpandableFilters 
                    title=""                    
                    classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
                    iconButtom="mdi-package-variant"
                    classTitle="col-0"
                    :filters=filters
                    @datafilters=getProducts                    
                /> 
             <v-btn 
                    class="mx-2 my-2 py-42 px-2 "
                    color="primary"
                  
                    small
                    @click="editProduct"
                >   
                    <v-icon  >mdi-package-variant</v-icon>
                    Crear Producto
                </v-btn>	    
             <v-btn 
                    class="mx-2 my-2 py-42 px-2 "
                    color="primary"
                    
                    
                    small
                    @click="exportarProductos"
                >   
                    <v-icon  >mdi-file-excel</v-icon>
                    Exportar lista
                </v-btn>	    
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn 
                    icon 
                    @click="editProduct(item.product_id)"
                >
                    <v-icon color='warning' >mdi-pencil</v-icon>
                </v-btn>					


               				
            </template>
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model=currentPage
                    :length=totalItems
                    @input="handlePageChange"
                    total-visible="15"
                ></v-pagination>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog" 
            max-width="1000"
            persistent
        >
            <v-tabs v-model=tab grow>
                <v-tab>
                    Datos generales
                </v-tab>
                <v-tab>
                    DESCRIPCIONES
                </v-tab>
                <v-tab>
                    IMÁGENES
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item class='mt-6'>
                    <v-card flat class='my-2'>
                        <v-form ref='form_general_data'>
                            <v-col>
                            <v-row>
                                <v-col><b>ID del producto:</b>  {{product.product_id}}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols=12 md=3 sm=6 class='pt-6'>
                                    <v-autocomplete
                                        v-model="product.product_type_id"
                                        :items=productTypes
                                        item-value="product_type_id"
                                        item-text="description"
                                        :rules=required
                                        label="Tipo de producto"
                                        dense
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <!-- <v-col cols=12 md=2 sm=6 class='pt-6'>
                                    <v-text-field
                                        v-model="product.product_id"
                                        dense
                                        label="Código del prodcuto"
                                        :disabled=newRegister
                                        :rules=required
                                    ></v-text-field>
                                </v-col> -->
                                <v-col cols=12 md=5 sm=6 class='pt-6'>
                                    <v-text-field
                                        v-model="product.product_name"
                                        dense
                                        label="Nombre del prodcuto"
                                        :rules=required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 md=4 sm=6 class='pt-6'>
                                    <v-text-field
                                        v-model="product.internal_name"
                                        dense
                                        label="Nombre interno"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 md=2 sm=6 class='pt-6'>
                                    <v-text-field
                                        v-model="product.brand_name"
                                        dense
                                        label="Marca"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 md=3 sm=6 class='pt-2'>
                                    <v-menu 
                                        v-model="datePicker"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="product.sales_discontinuation_date"
                                                label="No mostrar a partir del"
                                                persistent-hint
                                                hide-details
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="product.sales_discontinuation_date"
                                            no-title
                                            @change="datePicker = false"
                                            locale="es-es"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols=12 md=1 sm=6 class='pt-6'>
                                    <v-text-field
                                        v-model="product.pvp"
                                        dense
                                        label="P.V.P"
                                        type="number"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 md=3 sm=6 class='pt-6'>
                                    <v-autocomplete
                                        v-model="product.primary_category"
                                        :items=primaryCategories
                                        item-value="product_category_id"
                                        item-text="category_name"
                                        :rules=required
                                        label="Categoría principal"
                                        dense
                                        @change="primaryCategoryInsumo = product.primary_category == 'CAT_INSUMOS'"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col 
                                    cols=12 md=3 sm=6 
                                    class='pt-6'  
                                    v-if="!primaryCategoryInsumo"
                                >
                                    <v-autocomplete
                                        v-model="product.laboratory"
                                        :items=laboratories
                                        item-value="product_category_id"
                                        item-text="category_name"
                                        clearable
                                        label="Laboratorio"
                                        dense
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col 
                                    cols=12 md=3 sm=6 class='pt-6' 
                                    v-if="!primaryCategoryInsumo "
                                >
                                    <v-autocomplete
                                        v-model="product.concentracion_uom_id"
                                        :items=concentrations
                                        item-value="uom_id"
                                        item-text="description"
                                        label="Consentración"
                                        dense
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col 
                                    cols=12 md=3 sm=6 class='pt-6' 
                                    v-if="!primaryCategoryInsumo"
                                >
                                    <v-autocomplete
                                        v-model="product.forma_farmaceutica_id"
                                        :items=pharmaceuticalsForm
                                        item-value="forma_farmaceutica_id"
                                        item-text="descripcion"
                                        label="Forma farmaceutica"
                                        dense
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col 
                                    cols=12 md=3 sm=6 class='pt-6' 
                                    v-if="!primaryCategoryInsumo"
                                >
                                    <v-autocomplete
                                        v-model="product.principio_activo_id"
                                        :items=activeSubstances
                                        item-value="principio_activo_id"
                                        item-text="descripcion"
                                        label="Principio activo"
                                        dense
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col 
                                    cols=12 md=3 sm=6 class='pt-6' 
                                    
                                >
                                    <v-text-field
                                        v-model="product.codigo_barra"
                                        dense
                                        label="Código de barras"
                                        type="number"
                                    >
                                        <template v-slot:append-outer>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="printBarCode()"
                                                        icon
                                                        small
                                                        color="primary"
                                                    >
                                                        <v-icon>mdi-barcode-scan</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Imprimir código de barras</span>
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols=12>
                                    <v-row>
                                        <v-col cols=12 md=4>
                                            <v-radio-group
                                                v-model="product.cat_iva"
                                                :column="false"
                                                class="mt-0"
                                            >
                                                <v-radio
                                                    label="SI APLICA IVA"
                                                    color="primary"
                                                    value="CAT_IVA"
                                                    class="font-weight-bold"
                                                ></v-radio>
                                                <v-radio
                                                    class="ml-10 font-weight-bold"
                                                    label="NO APLICA IVA"
                                                    color="primary"
                                                    value="CAT_NO_IVA"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col 
                                            cols=12 md=6 class="pt-2" 
                                            v-if="!primaryCategoryInsumo"
                                        >
                                            <v-row>
                                                <v-col cols=12 sm=5 class="pt-0">
                                                    <v-checkbox
                                                        v-model="product.cat_psicotropico"
                                                        label="Es Psicotropico"
                                                        color="primary"
                                                        value="CAT_PSICOTROPICO"
                                                        hide-details
                                                        class="font-weight-bold"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols=12 sm=6 class="pt-0">
                                                    <v-checkbox
                                                        v-model="product.cat_controlado"
                                                        label="Es producto controlado"
                                                        color="primary"
                                                        value="CON_RECETA"
                                                        hide-details
                                                        class="font-weight-bold"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>      
                                <v-col cols=12 class='pt-0'>
                                    <v-alert
                                        dense
                                        icon="mdi-format-list-bulleted"
                                        type="info"
                                    >
                                        Método de reserva de inventario
                                    </v-alert>
                                    <v-col cols=8>
                                        <v-autocomplete
                                            v-model='product.enum_inventory_reserve_method'
                                            :items=inventoryReserveTypes
                                            item-value='enum_id'
                                            item-text='description'
                                            dense
                                            label='Seleccione el método de reseva de inventario para el producto'
                                            prepend-inner-icon='mdi-format-list-checks'
                                        ></v-autocomplete>
                                    </v-col>
                                </v-col>                          
                                <v-col cols=12 class='pt-0'>
                                    <v-alert
                                        dense
                                        icon="mdi-format-list-bulleted"
                                        type="info"
                                    >
                                        CATEGORÍAS APLICADAS
                                    </v-alert>
                                    <v-col cols=8>
                                        <v-autocomplete
                                            v-model='categoriesApplied'
                                            :items=categories
                                            item-value='product_category_id'
                                            item-text='category_name'
                                            dense
                                            label='Seleccione las categorías'
                                            prepend-inner-icon='mdi-format-list-checks'
                                            multiple
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-row>
                                        <v-col cols=12>
                                            <ul class="pl-0">
                                                <li
                                                    v-for="(category, x) in product.categories.filter(e => e.product_category_id != 'CAT_IVA' && e.product_category_id != 'CAT_NO_IVA')"
                                                    :key="`category-${x}`"
                                                    style="display:inline"
                                                    class="ml-2"
                                                >
                                                    <v-icon class="mb-1">mdi-check</v-icon> {{category.category_name}} 
                                                    <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            color="red"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="deleteCategory(category.product_category_id,product.product_id)"
                                                        >mdi-delete-forever</v-icon>
                                                    </template>
                                                    <span>Eliminar la categoría</span>
                                                    </v-tooltip>
                                                </li>   
                                            </ul>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols=12 class='pt-0'>
                                    <v-alert
                                        dense
                                        icon="mdi-account-switch"
                                        type="info"
                                    >
                                        PROVEEDORES
                                    </v-alert>
                                    <v-col cols=12>
                                        <v-autocomplete
                                            v-model='suppliersSelected'
                                            :items=suppliers
                                            item-value='party_id'
                                            item-text='group_name'
                                            dense
                                            label='Seleccione los proveedores'
                                            prepend-inner-icon='mdi-format-list-checks'
                                            multiple
                                            @change="addProductSuplier()"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-row 
                                        v-for="(supplier, x) in product.suppliers"
                                        :key="`supplier-${x}`"
                                    >
                                        <v-col cols=12 class='px-5'>
                                            <v-row>
                                                <v-col cols=12 md=2>
                                                    <v-text-field
                                                        v-model=supplier.last_price
                                                        dense
                                                        label="Último precio"
                                                        type="number"
                                                        min="0"
                                                        :rules=required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols=12 md=3>
                                                    <v-text-field
                                                        v-model=supplier.supplier_product_id
                                                        dense
                                                        label="Cod. de producto del proveedor"
                                                        :rules=required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols=12 md="7" class='mt-3'>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span v-bind="attrs" v-on="on" >
                                                                {{supplier.group_name.length > 50 ? supplier.group_name.substr(0,50)+'...' : supplier.group_name}}
                                                                 <v-icon 
                                                                    color="red" 
                                                                    @click="deleteSupplier(supplier,product.product_id)"
                                                                >
                                                                    mdi-delete-forever
                                                                </v-icon>
                                                            </span>
                                                        </template>
                                                        <span> {{supplier.group_name}} </span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn 
                                    class="text-center" 
                                    color='primary'
                                    :loading=loadingBtn
                                    @click="storeProduct"
                                >
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn 
                                    class="text-center" 
                                    color='default' 
                                    @click="close"
                                >
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6'>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref='form_description_data'>
                            <div class="mt-5">
                                <v-icon>mdi-pencil</v-icon>
                                <b>TÍTULO WEB DEL PRODUCTO</b>
                            </div>
                            <v-textarea
                                outlined
                                v-model="product.product_name_web" 
                                placeholder="ESCRIBE AQUÍ EL TÍTULO WEB DEL PRODUCTO"
                                :rules="required"
                                :value=product.product_name_web
                            ></v-textarea>
                            <!-- <tiptap-vuetify 
                                v-model="product.product_name_web" 
                                :extensions="extensions"
                                placeholder="ESCRIBE AQUÍ EL TÍTULO WEB DEL PRODUCTO"
                                :rules="required"
                                :card-props="{ height:'100px' }"
                            /> -->
                            <div class="mt-5">
                                <v-icon>mdi-pencil</v-icon>
                                <b>DESCRIPCIÓN CORTA DEL PRODUCTO</b>
                            </div>
                            <tiptap-vuetify 
                                v-model="product.short_description" 
                                :extensions="extensions"
                                placeholder="ESCRIBE LA DESCRIPCIÓN CORTA DEL PRODUCTO"
                                :rules="required"
                                :card-props="{ height:'250px' }"
                            />
                            <div class="mt-5">
                                <v-icon>mdi-pencil</v-icon>
                                <b>DESCRIPCIÓN LARGA DEL PRODUCTO</b>
                            </div>
                            <tiptap-vuetify 
                                v-model="product.long_description" 
                                :extensions="extensions"
                                placeholder="ESCRIBE LA DESCRIPCIÓN LARGA DEL PRODUCTO"
                                :rules="required"
                                :card-props="{ height:'250px' }"
                            />
                            <v-col class='text-center mt-5'>
                                <v-btn 
                                    class="text-center" 
                                    color='primary'
                                    :loading=loadingBtn
                                    @click="storeWebDescription"
                                >
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn 
                                    class="text-center" 
                                    color='default' 
                                    @click="close"
                                >
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6'>
                    <v-card flat class='my-2 mx-2'>
                        <v-alert
                            dense
                            icon="mdi-format-list-bulleted"
                            type="info"
                            class="mx-3"
                        >
                            CARGUE LA IMAGEN WEB DEL PRODUCTO, <b>TAMAÑO RECOMENDADO 800x800</b>
                        </v-alert>
                        <v-form ref='form_image_product'>
                            <v-file-input
                                v-model=image
                                label="Imagen del producto"
                                outlined
                                dense
                                :rules=required
                                prepend-icon=""
                                accept="image/*"
                                class="mx-3"
                                @change=setFile($event)
                            ></v-file-input>
                        </v-form>
                        <v-col cols=12>
                            <v-row>
                                <v-col cols=12 md=6>
                                    <v-card class="mx-auto">
                                        <template slot="progress">
                                        <v-progress-linear
                                            color="deep-purple"
                                            height="10"
                                            indeterminate
                                        ></v-progress-linear>
                                        </template>
                                        <v-img :src="urlImgMedium"></v-img>                        
                                        <v-card-title>IMAGEN DEL LISTADO</v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols=12 md=6>
                                    <v-card class="mx-auto">
                                        <template slot="progress">
                                            <v-progress-linear
                                                color="deep-purple"
                                                height="10"
                                                indeterminate
                                            ></v-progress-linear>
                                        </template>
                                        <v-img :src="urlImgLarge"></v-img>                        
                                        <v-card-title>IMAGEN INTERNA</v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-col class='text-center mt-5'>
                                <v-btn 
                                    class="text-center" 
                                    color='primary'
                                    :loading=loadingBtn
                                    @click="storeImgProduct"
                                >
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn 
                                    class="text-center" 
                                    color='default' 
                                    @click="close"
                                >
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-col>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-dialog>
        <iframe name="framePrint" style="width:0px;height:0px;visibility:hidden"></iframe>
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import ExpandableFilters from '../general/ExpandableFilters'
import { 
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, 
    Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, 
    HorizontalRule, History 
} from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import Vue from 'vue'
import axios from 'axios'

export default{
    name:'ProductsComponent',
    components:{
        ExpandableFilters,
        TiptapVuetify
    },
    data:()=>({
        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [
                Heading, {
                    options: {
                        levels: [1, 2, 3,4, 5, 6]
                    }
                }
            ],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ],
        headers: [
			{ text: 'Código', value: 'product_id' },
			{ text: 'Nombre interno', value: 'internal_name' },
            { text: 'Nombre de marca', value: 'brand_name' },
            { text: 'Nombre de producto', value: 'product_name' },
            { text: 'Acciones', value: 'actions' },
        ],
        filters:[
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Código',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Nombre',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Categoría',
                type:'input_select',
                text:'category_name',
                value:'product_category_id',
                clearable:true,
                items:[
                    
                ]
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Estado',
                type:'input_select',
                text:'name',
                value:'state',
                items:[
                    {'state': 'active','name':'Activos'},
                    {'state':'inactive','name': 'Inactivos'}
                ],
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Proveedor',
                type:'input_text'
            }
        ],
        product:{
            product_id:null,
            internal_name:null,
            brand_name:null,
            product_name:null,
            cat_iva: 'CAT_NO_IVA',
            product_type_id:'FINISHED_GOOD',
            cat_psicotropico:'',
            cat_controlado:'',
            primary_category:'',
            consentration:'',
            laboratory:'',
            principio_activo_id:'',
            pvp:0,
            forma_farmaceutica_id:'',
            enum_inventory_reserve_method:null,
            concentracion_uom_id:'',
            sales_discontinuation_date:'',
            short_description:null,
            long_description:null,
            product_name_web:null,
            codigo_barra:null,
            imgProduct:null,
            categories:[],
            suppliers:[]
        },
        defaultProduct:{
            product_id:null,
            internal_name:null,
            brand_name:null,
            product_name:null,
            cat_iva: 'CAT_NO_IVA',
            cat_psicotropico:'',
            cat_controlado:'',
            product_type_id:'FINISHED_GOOD',
            primary_category:'',
            consentration:'',
            laboratory:'',
            forma_farmaceutica_id:'',
            principio_activo_id:'',
            concentracion_uom_id:'',
            sales_discontinuation_date:'',
            short_description:null,
            long_description:null,
            product_name_web:null,
            enum_inventory_reserve_method:null,
            imgProduct:null,
            codigo_barra:null,
            pvp:0,
            categories:[],
            suppliers:[]
        },
        inventoryReserveTypes:[
            {type: 'FIFO', name: 'Primero en entrar, primero en salir por fecha recibido (FIFO)'},
            {type: 'LIFO', name: 'Último en entrar, primero en salir por fecha recibido (LIFO)'},
            {type: 'FIFO_EXPIRED_DATE', name: 'Primero en entrar, primero en salir por fecha de expiración (FIFO)'},
            {type: 'LIFO_EXPIRED_DATE', name: 'Último en entrar, primero en salir por fecha de expiración (LIFO)'},
        ],
        image:null,
        printer:{},
        urlImgMedium:null,
        urlImgLarge:null,
        primaryCategoryInsumo: false,
        datePicker:false,
        newRegister:false,
        dataTable:[],
        categories:[],
        categoriesApplied:[],
        suppliersSelected:[],
        productTypes:[],
        laboratories:[],
        primaryCategories:[],
        concentrations:[],
        pharmaceuticalsForm:[],
        activeSubstances:[],
        suppliers:[],
        totalItems:1,
        currentPage:1,
        itemsPerPage:20,
        dialog:false,
        tab: null,
        options:{},
        required:[v => !!v || 'El campo es requerido'],    
    }),
    computed: {
    	...mapState('master',[
            'loadingTable',
            'tenantId',
            'loadingBtn',
            'paramAlertQuestion',
            'user',
            'token',
            'url',
            'urlApi'
        ]),
    },
    watch:{
        options: {
            handler () {
                this.getProducts()
            },
            deep: true
        }
    },
    methods:{

        ...mapMutations('master',['setLoadingTable','setUrl','setLoadingBtn']),

        ...mapActions('master',['requestApi','alertNotification']),

        getProducts(){

            const {sortDesc} = this.options; 
            
            this.setLoadingTable(true)
            this.setUrl('product')
            this.dataTable=[]
            this.requestApi({
				method: 'GET',
				data: {
                    page: this.currentPage, 
			        itemsPerPage: this.itemsPerPage,
                    state : this.filters[3].v_model,
                    category: this.filters[2].v_model,
                    code: this.filters[0].v_model,
                    supplier: this.filters[4].v_model,
                    name: this.filters[1].v_model,
                    sortDesc: typeof sortDesc[0] !='undefined' ? sortDesc[0] : false,
                }
			}).then(res =>{

                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.product
                                  
			}).then(()=>{
                this.setLoadingTable(false)
            })

        },

        editProduct(productId){
            
            this.setLoadingTable(true)
            let noProductId = typeof productId =='object'
            this.newRegister = !noProductId
            this.setUrl(`product/${noProductId ? 0 : productId}`)

            this.requestApi({
				method: 'GET',
				data: {
                    
                }
			}).then(res =>{
                    console.log(res.data.product)
                this.product.cat_iva = typeof res.data.product.categories.find(e => e.product_category_id == 'CAT_IVA') !='undefined' 
                                        ? 'CAT_IVA' : 'CAT_NO_IVA'
                this.categories= res.data.categories
                this.productTypes = res.data.productTypes
                this.laboratories = res.data.laboratories
                this.primaryCategories = res.data.primaryCategories
                this.concentrations = res.data.concentrations
                this.pharmaceuticalsForm = res.data.pharmaceuticals_form
                this.activeSubstances= res.data.activeSubstances
                this.suppliers = res.data.suppliers
                this.printer = res.data.printer
                Object.assign(this.product,res.data.product)
                this.suppliersSelected = this.product.suppliers.map( obj => { return obj.party_id })
                this.urlImgMedium = `${this.urlApi}img/products/${this.product.medium_image_url}`
                this.urlImgLarge = `${this.urlApi}img/products/${this.product.large_image_url}`
                this.primaryCategoryInsumo = this.product.primary_category == 'CAT_INSUMOS'
                this.inventoryReserveTypes = res.data.inventoryReserveMethods
                this.dialog=true
                
			}).then(()=>{
                this.setLoadingTable(false)
            })
            
        },

        getCategories(){
            this.setUrl('product')
            this.requestApi({
				method: 'PUT',
				data: {
                    
                }
			}).then(res =>{
                console.log(res)
                this.filters[2].items= res.data._embedded.product
                   
			})
        },
        
        deleteCategory(product_category_id,product_id){   

            Vue.swal({
                    html: "Está seguro de eliminar esta categoría?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then( result => {
                    
                    if (result.isConfirmed) {

                        this.setUrl('product')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                typeDelete: 'category',
                                product_id:product_id,
                                product_category_id: product_category_id
                            }
                        }).then(res =>{

                            let obj = this.product.categories.find(e => e.product_category_id == product_category_id)

                            if(typeof obj!='undefined'){
                                let index = this.product.categories.indexOf(obj)
                                this.product.categories.splice(index,1)
                            }
                            
                            this.alertNotification({
                                param:{
                                    html: res.data.detail
                                }
                            })   
                            
                        })

                    }

                })

        },

        deleteSupplier(supplier,product_id){
            
            let index  = this.product.suppliers.indexOf(supplier)

            if(typeof supplier.new !='undefined'){

                this.product.suppliers.splice(index,1)
                let obj = this.suppliersSelected.find(e => e == supplier.party_id)
                if(typeof obj !='undefined'){
                    let index = this.suppliersSelected.indexOf(obj)
                    this.suppliersSelected.splice(index,1)
                }

            }else{
                
                Vue.swal({
                    html: "Está seguro de eliminar este proveedor?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then( result => {
                    
                    if (result.isConfirmed) {

                        this.setUrl('product')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                typeDelete: 'productSupplier',
                                product_id:product_id,
                                party_id: supplier.party_id
                            }
                        }).then(res =>{
                            
                            this.product.suppliers.splice(index,1)
                            this.alertNotification({
                                param:{
                                    html: res.data.detail
                                }
                            })   
                            
                        })

                    }

                })

            }
        },

        addProductSuplier(){
            this.suppliersSelected.map( party_id => {
                if(typeof this.product.suppliers.find(e => e.party_id == party_id) == 'undefined'){
                    let supplier = this.suppliers.find(e => e.party_id == party_id)
                    this.product.suppliers.push({
                        group_name: supplier.group_name,
                        party_id : supplier.party_id,
                        last_price: 0,
                        supplier_product_id:null,
                        new:true
                    })
                }
            })
        },

        storeProduct(){

            if(!this.$refs.form_general_data.validate())
                return false

            this.setUrl('product')
            this.requestApi({
                method: 'POST',
                data: {
                    product : this.product,
                    categoriesApplied: this.categoriesApplied,
                    typeStore:'generalData'
                }
            }).then(res =>{

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })   
                
            })

        },

        storeWebDescription(){

            //if(!this.$refs.form_description_data.validate())
              //  return 

            this.setUrl('product')
            this.requestApi({
                method: 'POST',
                data: {
                    productId : this.product.product_id,
                    product_name_web: this.product.product_name_web,
                    short_description: this.product.short_description,
                    long_description: this.product.long_description,
                    typeStore:'webInformation'
                }
            }).then(res =>{
                
                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })   
                
            })

        },

        setFile(event){

            this.product.imgProduct = typeof event != 'undefined' ? event : null

            if(this.image != null && this.image!=''){
                this.urlImgMedium = URL.createObjectURL(this.image)
                this.urlImgLarge = URL.createObjectURL(this.image)
            }else{
                this.urlImgMedium = null
                this.urlImgLarge= null
            }

        },

        storeImgProduct(){

            if(!this.$refs.form_image_product.validate())
              return false

            this.setLoadingBtn(true)
            this.setUrl('product')
        
            let formData = new FormData()
            
            formData.append('imgProduct',this.product.imgProduct)
            formData.append('product_id',this.product.product_id)
            formData.append('tenantId',this.tenantId)
            formData.append('user',this.user.user_ligin_id)
            formData.append('pass',this.user.pass)
            formData.append('typeStore','imageWeb')

            axios.post(
                this.url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+ this.token
                    }
                }
            ).then(res => {
                console.log(res)
                this.setLoadingBtn(false)
                this.alertNotification({
                    param:{
                        html: res.data.res.msg,
                        timer: 130000
                    }
                })
                this.product.imgProduct=null
            
            }).catch(err=>{
			
               // console.log(err)
                this.getProducts()
                this.setLoadingBtn(false)

                params={
                    title :'Error!',
                    html: err.data.detail,
                    icon:'error',
                    confirmButtonColor:'#d60400',
                    toast:false,
                    grow:false,
                    position:'center',
                    confirmButtonText:'Cerrar'
                }

                Vue.swal({...params});

            })


        },

        close(){
            this.product.product_id=null
            Object.assign(this.product,this.defaultProduct)
            this.dialog=false
        },

        handlePageChange(value) {
            this.currentPage = value
            this.getProducts()
        },

        printBarCode(){
            
            if(this.product.codigo_barra=='' || this.product.codigo_barra==null){
                Vue.swal({
                    html: 'Debe escribir o scanear el código de barras del producto',
                    icon: 'warning'
                })
                return false
            }

            if(this.printer.ip =='' || this.printer.printer ==''){
                Vue.swal({
                    html: 'No se configuró una impresora para imprimir el código de barras del producto',
                    icon: 'warning'
                })
                return false
            }
            
            window.frames['framePrint'].location.replace(`https://${this.printer.ip}/impresion/zpl/src/codigo_barra.php?codigo_barra=${this.product.codigo_barra}&impresora=${this.printer.printer}`)

        },
        exportarProductos() {
            this.overlay=true
            this.setUrl('product')
            const {sortDesc} = this.options; 

            this.requestApi({
                method : 'POST',
                data : { 
                    typeStore: 'exportarLista',
                    state : this.filters[3].v_model,
                    category: this.filters[2].v_model,
                    code: this.filters[0].v_model,
                    supplier: this.filters[4].v_model,
                    name: this.filters[1].v_model,
                    sortDesc: typeof sortDesc[0] !='undefined' ? sortDesc[0] : false,
                }
            }).then(res=>{
                
            	//let win = window.open()
                //let base64URL = `data:application/pdf;base64,${res.data.detail[2].trim().replace('"',"").replace(/(\r\n|\n|\r)/gm,"")}`
                //win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
                console.log(res.data.detail);
                

                var a = document.createElement("a");
            	a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.detail.datos;
            	a.download = res.data.detail.archivo;
            	a.click();

                //a.href = "data:application/pdf;base64," + res.data.detail.trim().replace('"',"").replace(/(\r\n|\n|\r)/gm,""); 
                //a.download = 'lista-productos.pdf';                
                //a.click();  

            }).catch(()=>{

            }).then(()=>{
                this.overlay=false
            })

        }   ,

    },
    created(){
        this.getCategories()
    }

}
</script>

<style >
    /* .tiptap-vuetify-editor__content{
        height: 150px;
    } */
</style>